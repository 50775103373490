import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Space, Switcher } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks, P } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "switcher"
    }}>{`Switcher`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Switcher } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2758%3A0'} storybook={'/?path=/story/inputs-switcher--playground'} vueStorybook={'/?path=/story/inputs-switcher--playground'} name={'Switcher'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Switcher — это компонент переключателя между состояниями `}<inlineCode parentName="p">{`on`}</inlineCode>{`/`}<inlineCode parentName="p">{`off`}</inlineCode>{`.`}</p>
    <p>{`Компоненту можно добавить лейбл с помощью пропа `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <p>{`Выбранным состоянием компонента нужно управлять извне. Передайте пропы `}<inlineCode parentName="p">{`checked`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <p>{`У `}<inlineCode parentName="p">{`Switcher`}</inlineCode>{` 2 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`small`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный размер.`}</p>
    <p><inlineCode parentName="p">{`Switcher`}</inlineCode>{` можно сделать неактивным, передав проп `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`. В данном состоянии действия пользователя игнорируются.`}</p>
    <p>{`Прочие пропы прокидываются на элемент `}<inlineCode parentName="p">{`input`}</inlineCode>{` с классом `}<inlineCode parentName="p">{`hse-Switcher__input`}</inlineCode></p>
    <Playground __position={1} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <Switcher\n      checked={checked}\n      onChange={setChecked}\n      label={\'Подписаться на новостную рассылку\'}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Space,
      Switcher,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      P,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [checked, setChecked] = useState(false);
        return <Switcher checked={checked} onChange={setChecked} label={'Подписаться на новостную рассылку'} mdxType="Switcher" />;
      }}
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=8799%3A592'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=8799%3A594'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "лейбл"
    }}>{`Лейбл`}</h3>
    <p>{`Компоненту можно добавить лейбл с помощью пропа `}<inlineCode parentName="p">{`label`}</inlineCode></p>
    <Playground __position={4} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <Switcher\n      checked={checked}\n      onChange={setChecked}\n      label={\'Подписаться на новостную рассылку\'}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Space,
      Switcher,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      P,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [checked, setChecked] = useState(false);
        return <Switcher checked={checked} onChange={setChecked} label={'Подписаться на новостную рассылку'} mdxType="Switcher" />;
      }}
    </Playground>
    <h3 {...{
      "id": "неактивное-состояние"
    }}>{`Неактивное состояние`}</h3>
    <p><inlineCode parentName="p">{`Switcher`}</inlineCode>{` можно сделать неактивным, передав проп `}<inlineCode parentName="p">{`inactive`}</inlineCode>{`. В данном состоянии действия пользователя игнорируются`}</p>
    <Playground __position={5} __code={'<Switcher\n  inactive={true}\n  checked={true}\n  label={\'Подписаться на новостную рассылку\'}\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Space,
      Switcher,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      P,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Switcher inactive={true} checked={true} label={'Подписаться на новостную рассылку'} mdxType="Switcher" />
    </Playground>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У `}<inlineCode parentName="p">{`Switcher`}</inlineCode>{` 2 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, по умолчанию используется `}<inlineCode parentName="p">{`small`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный размер`}</p>
    <Playground __position={6} __code={'() => {\n  const [checked, setChecked] = useState(false)\n  return (\n    <>\n      <Switcher\n        checked={checked}\n        onChange={setChecked}\n        label={\'Подписаться на новостную рассылку\'}\n      />\n      <Space size={Space.Size.small} vertical />\n      <Switcher\n        checked={checked}\n        onChange={setChecked}\n        size={Switcher.Size.medium}\n        label={\'Подписаться на новостную рассылку\'}\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Space,
      Switcher,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      P,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [checked, setChecked] = useState(false);
        return <>
        <Switcher checked={checked} onChange={setChecked} label={'Подписаться на новостную рассылку'} mdxType="Switcher" />
        <Space size={Space.Size.small} vertical mdxType="Space" />
        <Switcher checked={checked} onChange={setChecked} size={Switcher.Size.medium} label={'Подписаться на новостную рассылку'} mdxType="Switcher" />
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Switcher} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на элемент `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      